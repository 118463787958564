/* eslint-disable no-restricted-syntax */
// Extract refresh settings from window
const {
  vsDefaultRefresh,
  vsStickyRefresh,
  vsDefaultInViewDuration,
  vsStickyAdUnits,
  vsExcludedAdUnits,
} = window.adConfig;

const adSlotState = {};

const options = {
  rootMargin: "0px",
  threshold: 0.5,
};

const callback = (entries) => {
  entries.forEach((entry) => {
    const slotId = entry.target.id;

    // init slot key if not exist
    if (!(slotId in adSlotState)) {
      adSlotState[slotId] = {
        total_seconds_in_view: 0,
        consecutive_seconds_in_view: 0,
        loaded_for_in_seconds: 0,
      };
    }

    // set view state for slot
    if (entry.isIntersecting) {
      adSlotState[slotId].in_view = true;
    } else {
      adSlotState[slotId].in_view = false;
      adSlotState[slotId].consecutive_seconds_in_view = 0;
    }
  });
};

// For non-sticky ad units
// By default 20 seconds or what is set in vsStickyRefresh must have passed since the last time the ad unit has been displayed (or refreshed) This value may be overrode.
// With the above condition met, the ad should have been in view for a total of 6 seconds (non-consecutive) unless overrode, before it can be refreshed.
const observer = new IntersectionObserver(callback, options);

export function observeAdSlotForRefresh(id) {
  const target = document.getElementById(id);
  observer.observe(target);
}

function checkForAdRefresh() {
  // loop through all ad slot states
  for (const [adSlotId, adState] of Object.entries(adSlotState)) {
    // if this ad is excluded
    if (vsExcludedAdUnits.includes(adSlotId)) {
      continue;
    }

    // if this is a sticky ad, it has to be in view for consecutive default (20) seconds to be refreshed
    // or if it is a non-sticky ad and default(20) seconds must have passed since the last time the ad unit has been displayed (or refreshed).
    // if not-sticky ad, then the ad should have been in view for a total of default(6) seconds (non-consecutive), before it can be refreshed.
    if (
      (vsStickyAdUnits.includes(adSlotId) &&
        adState.consecutive_seconds_in_view >= vsStickyRefresh) ||
      (!vsStickyAdUnits.includes(adSlotId) &&
        adState.loaded_for_in_seconds >= vsDefaultRefresh &&
        adState.total_seconds_in_view >= vsDefaultInViewDuration &&
        adState.in_view)
    ) {
      refreshAdSlot(adSlotId);
      adState.loaded_for_in_seconds = 0;
      adState.total_seconds_in_view = 0;
      adState.consecutive_seconds_in_view = 0;
      continue;
    }

    // increase seconds in view timers
    if (adState.in_view) {
      adState.total_seconds_in_view++;
      adState.consecutive_seconds_in_view++;
    }

    // increase timer of how long this ad has been loaded
    adState.loaded_for_in_seconds++;
  }
}

// Sortable Overrides to get prebid working with refresh
function requestBids(tag) {
  const adUnitCode = `${tag.getAdUnitPath()}_${tag.getSlotId().getDomId()}`;
  window.pbjsSortable.requestBids({
    timeout: window.pbjsSortable.getConfig("bidderTimeout"),
    adUnitCodes: [adUnitCode],
    bidsBackHandler: bidsBackHandler(tag, adUnitCode),
  });
}
// Callback function after the bid returns
function bidsBackHandler(tag, adUnitCode) {
  const targeting =
    window.pbjsSortable.getAdserverTargetingForAdUnitCode(adUnitCode);
  for (const [key, value] of Object.entries(targeting)) {
    googletag.cmd.push(() => {
      tag.setTargeting(key, value);
    });
  }
  googletag.cmd.push(() => {
    googletag.pubads().refresh([tag]);
  });
}

function refreshAdSlot(adSlotId) {
  const tag = window.tagMaps[adSlotId];
  if (window.pbjsSortable && !window.adConfig.useVanillaGPT) {
    requestBids(tag);
  } else {
    googletag.cmd.push(() => {
      googletag.pubads().refresh([tag]);
    });
  }
}

// run checkForAdRefresh every second
setInterval(checkForAdRefresh, 1000);
