// NOTE: this file is part of the legacy ad-stack.
// it's not currently being used in production,
// but the entry points have not yet been removed

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import postscribe from "postscribe";
import { California } from "./California";
import { XF } from "./XF";
import { isRubiconAdOnlyOrNsfw } from "./ads-common";
import { observeAdSlotForRefresh } from "./ads-refresh";
import { desktopAdhesionClose } from "./desktop-adhesion-close";
import { buildFreeStarAd } from "./freestar-ads";

const BILLBOARD_AD_SLOT_NAME = "Billboard";
const HOMEPAGE_BILLBOARD_AD_SLOT_NAME = "Homepage_Billboard";
const WHATS_NEW_BILLBOARD_AD_SLOT_NAME = "WhatsNew_Billboard";
const { browsi, browsiDesktop } = window;
const AMAZON_TAM_TIMEOUT_MILLISECONDS = 2000;

// Templates that support billboard ads
const billboardTemplates = new Map();
const adIdsToDisplay = [];
window.tagMaps = [];

function isBrowsiOn() {
  return browsi && (!window.vsIsDesktop || browsiDesktop);
}

function setPageTargetingForForum() {
  googletag.pubads().setTargeting("forum_name", window.adConfig.vsForumName);
  googletag.pubads().setTargeting("forum_id", window.adConfig.vsForumId);

  googletag.pubads().setTargeting(
    "isMarketPlace",
    // eslint-disable-next-line eqeqeq
    (window.adConfig.isMarketPlace == "1").toString()
  );
}

function setPageTargetingForThread() {
  googletag.pubads().setTargeting("thread_name", window.adConfig.vsThreadName);
  googletag.pubads().setTargeting("PageId", window.adConfig.vsThreadId);
  if (window.adConfig.template === "listing_view") {
    let tag = `Tag: ${window.adConfig.listingTag}, Price: ${window.adConfig.listingPriceRange}, Currency: ${window.adConfig.listingCurrency}`;
    if (window.adConfig.listingMake) {
      tag += `, Make: ${window.adConfig.listingMake}`;
    }
    if (window.adConfig.listingModel) {
      tag += `, Model: ${window.adConfig.listingModel}`;
    }
    // eslint-disable-next-line eqeqeq
    if (window.adConfig.listingYear != "0" && window.adConfig.listingYear) {
      tag += `, Year: ${window.adConfig.listingYear}`;
    }
    if (
      window.adConfig.listingMileageRange !== "N/A" &&
      window.adConfig.listingMileageRange
    ) {
      tag += `, Mileage: ${window.adConfig.listingMileageRange}, Mileage Unit: ${window.adConfig.listingUnitofMileage}`;
    }
    googletag.pubads().setTargeting("vehicleListingData", tag);
  }
}

function setPageTargetingForExperiments() {
  if (window.adConfig.gamExperimentGroup) {
    googletag
      .pubads()
      .setTargeting(
        "experiment",
        window.adConfig.gamExperimentGroup.substring(0, 40)
      );
  }
  if (window.adConfig.desktopAdhesionBackgroundExperimentName) {
    googletag
      .pubads()
      .setTargeting(
        "folder2",
        window.adConfig.desktopAdhesionBackgroundExperimentName
      );
  }
  if (window.adConfig.desktopAdhesionCookieDurationExperimentName) {
    googletag
      .pubads()
      .setTargeting(
        "folder1",
        window.adConfig.desktopAdhesionCookieDurationExperimentName
      );
  }
}

function setPageTargeting() {
  if (window.adConfig.vsForumName && window.adConfig.vsForumId) {
    setPageTargetingForForum();

    if (window.adConfig.vsThreadName && window.adConfig.vsThreadId) {
      setPageTargetingForThread();
    }
  }

  if (!window.adConfig.useFreeStarAds) {
    if (window.adConfig.amazonTAM) {
      googletag
        .pubads()
        .setTargeting("AmazonTAM", window.adConfig.amazonTAMKVValue);
    }
  } else {
    googletag.pubads().setTargeting("AmazonTAM", "FS");
  }
  googletag.pubads().setTargeting("PrebidTech", window.adConfig.prebidTech);
  googletag
    .pubads()
    .set(
      "page_url",
      [
        window.location.protocol,
        "//",
        window.location.host,
        window.location.pathname,
      ].join("")
    );
  googletag.pubads().setTargeting("sitename", window.adConfig.sitename);
  googletag.pubads().setTargeting("template", window.adConfig.template);
  googletag
    .pubads()
    .setTargeting("group_id", window.adConfig.userGroup.toString());
  googletag.pubads().setTargeting("group_name", window.adConfig.userGroupTitle);
  googletag
    .pubads()
    // eslint-disable-next-line eqeqeq
    .setTargeting("registered", (window.adConfig.userId != 0).toString());
  googletag.pubads().addEventListener("slotRenderEnded", (event) => {
    window.perfMark(`${event.slot.getSlotElementId()} rendered`);
  });
  googletag.pubads().addEventListener("slotOnload", (event) => {
    window.perfMark(`${event.slot.getSlotElementId()} loaded`);
    window.perfMeasure(
      `${event.slot.getSlotElementId()} time`,
      `${event.slot.getSlotElementId()} rendered`,
      `${event.slot.getSlotElementId()} loaded`
    );
  });

  Object.keys(window.adConfig.gptTargeting || {}).forEach((k) => {
    googletag.pubads().setTargeting(k, window.adConfig.gptTargeting[k]);
  });

  setPageTargetingForExperiments();

  if (window.adConfig.disableSRA) {
    googletag.pubads().setTargeting("folder1", "SRADisabled");
  } else {
    googletag.pubads().enableSingleRequest();
  }
  googletag.pubads().enableLazyLoad({
    fetchMarginPercent: window.adConfig.gptLazyLoadFetchMarginPercent,
    renderMarginPercent: window.adConfig.gptLazyLoadRenderMarginPercent,
    mobileScaling: window.adConfig.gptLazyLoadMobileScalingPercent / 100,
  });

  // freestar expriment tags
  if (window.adConfig.freestarExperiment) {
    googletag
      .pubads()
      .setTargeting(
        "category",
        window.adConfig.useFreeStarAds ? "freestar" : "sortable"
      );
  }
} // end setPageTargeting

function setAmazonTAMRefreshOverride() {
  const originalRefresh = googletag.pubads().refresh;
  function newRefresh(...args) {
    const amazonIneligibleUnits = [
      "Billboard",
      "Homepage_Billboard",
      "WhatsNew_Billboard",
      "RightRail_Vendor",
      "Homepage_Vendor_Mobile",
      "Homepage_Vendor_Mobile_About",
    ];
    const amazonEligibleUnits = [];
    args.forEach((slots) => {
      if (Array.isArray(slots)) {
        slots.forEach((ad) => {
          if (!amazonIneligibleUnits.includes(ad.getSlotElementId())) {
            amazonEligibleUnits.push(ad);
          }
        });
      }
    });
    if (amazonEligibleUnits.length > 0) {
      window.apstag.fetchBids(
        {
          slots: amazonEligibleUnits,
          timeout: AMAZON_TAM_TIMEOUT_MILLISECONDS,
        },

        () => {
          window.apstag.setDisplayBids();
          originalRefresh(args[0]);
        }
      );
    } else {
      originalRefresh(args[0]);
    }
  }
  googletag.pubads().refresh = newRefresh;
}

California.Ads = function Ads(adConfig) {
  this.Config = adConfig;

  // This declaration is to swap Billboard types based on whether the new feature toggle is enabled
  const billboardAdType =
    this.Config.isBillboardEnabled && this.Config.isEnhancedBillboardEnabled
      ? HOMEPAGE_BILLBOARD_AD_SLOT_NAME
      : BILLBOARD_AD_SLOT_NAME;

  // TODO: clean up logic between the two billboard feature toggles
  if (this.Config.isBillboardEnabled) {
    billboardTemplates.set("forum_list", BILLBOARD_AD_SLOT_NAME);
    if (!this.Config.onlyForumListBillboard) {
      billboardTemplates.set("california_index_view", billboardAdType);
    }
  }

  if (
    this.Config.isEnhancedBillboardEnabled &&
    !this.Config.onlyForumListBillboard
  ) {
    billboardTemplates.set("whats_new", WHATS_NEW_BILLBOARD_AD_SLOT_NAME);
    billboardTemplates.set("whats_new_posts", WHATS_NEW_BILLBOARD_AD_SLOT_NAME);
    billboardTemplates.set(
      "whats_new_looking_for_replies",
      WHATS_NEW_BILLBOARD_AD_SLOT_NAME
    );
    billboardTemplates.set(
      "xa_sc_whats_new_items",
      WHATS_NEW_BILLBOARD_AD_SLOT_NAME
    );
    billboardTemplates.set(
      "xfmg_whats_new_media",
      WHATS_NEW_BILLBOARD_AD_SLOT_NAME
    );
  }

  // this is to prevent null from being passed as a targeting value when the feature toggle is off
  this.currentView = this.Config.currentView ? this.Config.currentView : "card";

  this.dfpId = "/1030735/";

  this.instantiatedAds = [];

  this.refreshedAds = {};

  const largeDesktopScreen = 1425;

  const desktopAdhesionBuilder = googletag
    .sizeMapping()
    .addSize([largeDesktopScreen, 10], [728, 90]);
  if (this.Config.desktopAdhesionSmallerScreen) {
    desktopAdhesionBuilder.addSize([900, 10], [320, 50]).addSize([899, 0], []);
  } else {
    desktopAdhesionBuilder.addSize([0, 0], []);
  }
  const desktopAdhesionSizeMapping = desktopAdhesionBuilder.build();

  this.tagSizeMappings = {
    vsDesktopBillboardMapping: googletag
      .sizeMapping()
      .addSize([1024, 10], [970, 250])
      .addSize([0, 0], [])
      .build(),
    vsMutiSizeBillboardMapping: googletag
      .sizeMapping()
      .addSize(
        [1024, 10],
        [
          [970, 250],
          [728, 90],
        ]
      )
      .addSize([0, 0], [])
      .build(),
    vsDesktopBBMapping: googletag
      .sizeMapping()
      .addSize([900, 10], [300, 250])
      .addSize([0, 0], [])
      .build(),
    vsDesktopMultiBBMapping: googletag
      .sizeMapping()
      .addSize(
        [900, 10],
        [
          [300, 250],
          [300, 600],
        ]
      )
      .addSize([0, 0], [])
      .build(),
    vsDesktop300x120Mapping: googletag
      .sizeMapping()
      .addSize([900, 10], [300, 95])
      .addSize([0, 0], [])
      .build(),
    vsDesktopLBMapping: googletag
      .sizeMapping()
      .addSize([1110, 10], [728, 90])
      .addSize([0, 0], [])
      .build(),
    vsDesktopLBAdhesionMapping: desktopAdhesionSizeMapping,
    vsDesktopFluidMapping: googletag
      .sizeMapping()
      .addSize([900, 10], "fluid")
      .addSize([0, 0], [])
      .build(),
    vsMobileLBMapping: googletag
      .sizeMapping()
      .addSize([900, 10], [])
      .addSize([0, 0], [320, 50])
      .build(),
    vsMobileBBMapping: googletag
      .sizeMapping()
      .addSize([900, 10], [])
      .addSize(
        [0, 0],
        [
          [300, 250],
          [280, 250],
        ]
      )
      .build(),
    vsMobile300x120Mapping: googletag
      .sizeMapping()
      .addSize([900, 10], [])
      .addSize([0, 0], [300, 95])
      .build(),
    vsMobileFluidMapping: googletag
      .sizeMapping()
      .addSize([900, 10], [])
      .addSize([0, 0], "fluid")
      .build(),
    vsDesktopInThreadMapping: googletag
      .sizeMapping()
      .addSize([1110, 10], [[728, 90], "fluid"])
      .addSize([900, 10], ["fluid"])
      .addSize([0, 0], [])
      .build(),
    vsMobileInThreadMapping: googletag
      .sizeMapping()
      .addSize([900, 10], [])
      .addSize([0, 0], [[300, 250], [280, 250], "fluid"])
      .build(),
  };

  this.adSlots = {
    RightRail_Top: {
      adUnitPath: "RightRail_Top",
      size: [300, 250],
      mapping: this.tagSizeMappings.vsDesktopBBMapping,
      targeting: {
        Position: ["ATF"],
        AdSlot: ["RightRail_Top"],
      },
    },
    RightRail_Vendor: {
      adUnitPath: "RightRail_Vendor",
      size: [300, 95],
      mapping: this.tagSizeMappings.vsDesktop300x120Mapping,
      targeting: {
        Position: ["Vendor"],
        AdSlot: ["RightRail_Vendor"],
      },
    },
    RightRail_Middle_Adhesion: {
      adUnitPath: "RightRail_Middle_Adhesion",
      size: [300, 250],
      mapping: this.tagSizeMappings.vsDesktopBBMapping,
      targeting: {
        Position: ["BTF"],
        AdSlot: ["RightRail_Middle_Adhesion"],
      },
    },
    RightRail_Bottom_Adhesion: {
      adUnitPath: "RightRail_Bottom_Adhesion",
      size: [
        [300, 250],
        [300, 600],
      ],
      mapping: this.tagSizeMappings.vsDesktopMultiBBMapping,
      targeting: {
        Position: ["BTF", "Adhesion"],
        AdSlot: ["RightRail_Bottom_Adhesion"],
      },
    },
    Desktop_Adhesion: {
      adUnitPath: "Desktop_Adhesion",
      size: [
        [728, 90],
        [320, 50],
      ],
      mapping: this.tagSizeMappings.vsDesktopLBAdhesionMapping,
      targeting: {
        Position: ["ATF", "Adhesion"],
        AdSlot: ["Desktop_Adhesion"],
      },
    },
    ProfilePage_: {
      adUnitPath: "In-Thread_Middle",
      size: [728, 90],
      mapping: this.tagSizeMappings.vsDesktopLBMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Middle"],
      },
      iterated: true,
    },
    ProfilePage_Mobile_: {
      adUnitPath: "In-Thread_Lower_Mobile",
      size: [
        [300, 250],
        [280, 250],
      ],
      mapping: this.tagSizeMappings.vsMobileInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower_Mobile"],
      },
      mobile: true,
      iterated: true,
    },
    Homepage_Top_Mobile: {
      adUnitPath: "Homepage_Top_Mobile",
      size: [
        [300, 250],
        [280, 250],
      ],
      mapping: this.tagSizeMappings.vsMobileBBMapping,
      mobile: true,
      targeting: {
        Position: ["ATF"],
        AdSlot: ["Homepage_Top_Mobile"],
      },
    },
    Homepage_Vendor_Mobile: {
      adUnitPath: "Homepage_Vendor_Mobile",
      size: [300, 95],
      mapping: this.tagSizeMappings.vsMobile300x120Mapping,
      mobile: true,
      targeting: {
        Position: ["Vendor"],
        AdSlot: ["Homepage_Vendor_Mobile"],
      },
    },
    Homepage_Billboard: {
      adUnitPath: HOMEPAGE_BILLBOARD_AD_SLOT_NAME,
      size: [970, 250],
      mapping: this.tagSizeMappings.vsDesktopBillboardMapping,
      targeting: {
        Position: ["ATF", "Premium"],
        AdSlot: [HOMEPAGE_BILLBOARD_AD_SLOT_NAME],
      },
    },
    WhatsNew_Billboard: {
      adUnitPath: WHATS_NEW_BILLBOARD_AD_SLOT_NAME,
      size: [970, 250],
      mapping: this.tagSizeMappings.vsDesktopBillboardMapping,
      targeting: {
        Position: ["ATF", "Premium"],
        AdSlot: [WHATS_NEW_BILLBOARD_AD_SLOT_NAME],
      },
    },
    "In-Feed_TrendingDiscussions": {
      adUnitPath: "In-Feed_TrendingDiscussions",
      alwaysShow: true,
      size: "fluid",
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Feed"],
        AdSlot: ["In-Feed_TrendingDiscussions"],
      },
    },
    "In-Thread_Middle": {
      adUnitPath: "In-Thread_Middle",
      size: [[728, 90], "fluid"],
      mapping: this.tagSizeMappings.vsDesktopInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Middle"],
      },
      minPostsOnpage: 5,
    },
    Mobile_Adhesion: {
      adUnitPath: "Mobile_Adhesion",
      size: [320, 50],
      mapping: this.tagSizeMappings.vsMobileLBMapping,
      mobile: true,
      targeting: {
        Position: ["ATF", "Adhesion"],
        AdSlot: ["Mobile_Adhesion"],
      },
    },
    "In-Thread_Lower_Mobile": {
      adUnitPath: "In-Thread_Lower_Mobile",
      size: [
        [300, 250],
        [280, 250],
      ],
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower_Mobile"],
      },
      mapping: this.tagSizeMappings.vsMobileBBMapping,
      mobile: true,
    },
    "In-Thread_Middle_Mobile": {
      adUnitPath: "In-Thread_Middle_Mobile",
      size: [[300, 250], [280, 250], "fluid"],
      mapping: this.tagSizeMappings.vsMobileInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Middle_Mobile"],
      },
      minPostsOnpage: 5,
      mobile: true,
      native: true,
    },
    "In-Feed_LatestPosts": {
      adUnitPath: "In-Feed_LatestPosts",
      alwaysShow: true,
      size: "fluid",
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Feed"],
        AdSlot: ["In-Feed_LatestPosts"],
      },
    },
    Homepage_Top_Mobile_About: {
      adUnitPath: "Homepage_Top_Mobile_About",
      size: [
        [300, 250],
        [280, 250],
      ],
      mapping: this.tagSizeMappings.vsMobileBBMapping,
      mobile: true,
      targeting: {
        Position: ["ATF"],
        AdSlot: ["Homepage_Top_Mobile_About"],
      },
    },
    Homepage_Vendor_Mobile_About: {
      adUnitPath: "Homepage_Vendor_Mobile_About",
      size: [300, 95],
      mapping: this.tagSizeMappings.vsMobile300x120Mapping,
      mobile: true,
      targeting: {
        Position: ["Vendor"],
        AdSlot: ["Homepage_Vendor_Mobile_About"],
      },
    },
    Billboard: {
      adUnitPath: BILLBOARD_AD_SLOT_NAME,
      size: [970, 250],
      mapping: this.tagSizeMappings.vsDesktopBillboardMapping,
      targeting: {
        Position: ["ATF", "Premium"],
        AdSlot: [BILLBOARD_AD_SLOT_NAME],
      },
    },
  };
  this.nonGranularThreadAds = {
    "In-Thread_Lower_": {
      adUnitPath: "In-Thread_Lower",
      size: [[728, 90], "fluid"],
      mapping: this.tagSizeMappings.vsDesktopInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower"],
      },
      minPostsOnpage: 9,
      iterated: true,
      native: true,
    },
    "In-Thread_Lower_Mobile_": {
      adUnitPath: "In-Thread_Lower_Mobile",
      size: [[300, 250], [280, 250], "fluid"],
      mapping: this.tagSizeMappings.vsMobileInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower_Mobile"],
      },
      minPostsOnpage: 9,
      mobile: true,
      iterated: true,
      native: true,
    },
  };
  this.granularThreadAds = {
    "In-Thread_Lower_1": {
      adUnitPath: "In-Thread_Lower",
      size: [[728, 90], "fluid"],
      mapping: this.tagSizeMappings.vsDesktopInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower"],
      },
      minPostsOnpage: 9,
      native: true,
    },
    "In-Thread_Lower_2": {
      adUnitPath: "In-Thread_Lower_2",
      size: [[728, 90], "fluid"],
      mapping: this.tagSizeMappings.vsDesktopInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower"],
      },
      minPostsOnpage: 13,
      native: true,
    },
    "In-Thread_Lower_3": {
      adUnitPath: "In-Thread_Lower_3",
      size: [[728, 90], "fluid"],
      mapping: this.tagSizeMappings.vsDesktopInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower"],
      },
      minPostsOnpage: 17,
      native: true,
    },
    "In-Thread_Lower_Mobile_1": {
      adUnitPath: "In-Thread_Lower_Mobile",
      size: [[300, 250], [280, 250], "fluid"],
      mapping: this.tagSizeMappings.vsMobileInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower_Mobile"],
      },
      minPostsOnpage: 9,
      mobile: true,
      native: true,
    },
    "In-Thread_Lower_Mobile_2": {
      adUnitPath: "In-Thread_Lower_Mobile_2",
      size: [[300, 250], [280, 250], "fluid"],
      mapping: this.tagSizeMappings.vsMobileInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower_Mobile"],
      },
      minPostsOnpage: 13,
      mobile: true,
      native: true,
    },
    "In-Thread_Lower_Mobile_3": {
      adUnitPath: "In-Thread_Lower_Mobile_3",
      size: [[300, 250], [280, 250], "fluid"],
      mapping: this.tagSizeMappings.vsMobileInThreadMapping,
      targeting: {
        NativeStyle: this.Config.currentStyle,
        NativeView: this.currentView,
        Position: ["BTF", "In-Thread"],
        AdSlot: ["In-Thread_Lower_Mobile"],
      },
      minPostsOnpage: 17,
      mobile: true,
      native: true,
    },
  };
  this.rubiconMapping = {
    RightRail_Top: {
      size: [300, 250],
      zonesize: "97080-15",
    },
    RightRail_Bottom_Adhesion: {
      size: [300, 600],
      zonesize: "97080-10",
    },
    Desktop_Adhesion: {
      size: [728, 90],
      zonesize: "97080-2",
    },
    Homepage_Top_Mobile: {
      size: [300, 250],
      zonesize: "97080-15",
    },
    Homepage_Top_Mobile_About: {
      size: [300, 250],
      zonesize: "97080-15",
    },
    "In-Thread_Middle": {
      size: [728, 90],
      zonesize: "97080-2",
    },
    Mobile_Adhesion: {
      size: [320, 50],
      zonesize: "97080-43",
    },
    "In-Thread_Lower_Mobile": {
      size: [300, 250],
      zonesize: "97080-15",
    },
    "In-Thread_Middle_Mobile": {
      size: [300, 250],
      zonesize: "97080-15",
    },
    ProfilePage_: {
      size: [728, 90],
      zonesize: "97080-2",
    },
    ProfilePage_Mobile_: {
      size: [300, 250],
      zonesize: "97080-15",
    },
  };
  this.nonGranularRubiconThreadAds = {
    "In-Thread_Lower_": {
      size: [728, 90],
      zonesize: "97080-2",
    },
    "In-Thread_Lower_Mobile_": {
      size: [300, 250],
      zonesize: "97080-15",
    },
  };
  this.granularRubiconThreadAds = {
    "In-Thread_Lower_1": {
      size: [728, 90],
      zonesize: "97080-2",
    },
    "In-Thread_Lower_2": {
      size: [728, 90],
      zonesize: "97080-2",
    },
    "In-Thread_Lower_3": {
      size: [728, 90],
      zonesize: "97080-2",
    },
    "In-Thread_Lower_Mobile_1": {
      size: [300, 250],
      zonesize: "97080-15",
    },
    "In-Thread_Lower_Mobile_2": {
      size: [300, 250],
      zonesize: "97080-15",
    },
    "In-Thread_Lower_Mobile_3": {
      size: [300, 250],
      zonesize: "97080-15",
    },
  };

  this.templateMaps = [
    {
      templates: [
        "california_index_view",
        "california_marketplace_home",
        "forum_list",
        "forum_view",
        "thread_view",
        "article_view",
        "listing_view",
        "deal_view",
        "category_view",
        "whats_new",
        "xa_sc_whats_new_items",
        "whats_new_posts",
        "xfmg_whats_new_media",
        "whats_new_looking_for_replies",
        "watched_forums_list",
        "watched_threads_list",
        "find_threads_list",
      ],
      adUnits: [
        "RightRail_Top",
        "RightRail_Vendor",
        "RightRail_Bottom_Adhesion",
        "Mobile_Adhesion",
      ],
    },
    {
      templates: ["xa_sc_item_view"],
      adUnits: [
        "RightRail_Top",
        "RightRail_Bottom_Adhesion",
        "In-Thread_Lower_Mobile",
        "Mobile_Adhesion",
      ],
    },
    {
      templates: ["xa_sc_index", "xa_sc_category_view"],
      adUnits: [
        "RightRail_Top",
        "RightRail_Bottom_Adhesion",
        "Mobile_Adhesion",
      ],
    },
    {
      templates: ["california_index_view"],
      adUnits: [
        "In-Feed_TrendingDiscussions",
        "Homepage_Top_Mobile",
        "Homepage_Vendor_Mobile",
        billboardAdType,
      ],
    },
    {
      templates: ["thread_view"],
      adUnits: ["In-Thread_Middle", "In-Thread_Middle_Mobile"],
    },
    {
      templates: ["article_view"],
      adUnits: ["In-Thread_Middle", "In-Thread_Middle_Mobile"],
    },
    {
      templates: ["listing_view"],
      adUnits: ["In-Thread_Middle", "In-Thread_Middle_Mobile"],
    },
    {
      templates: ["deal_view"],
      adUnits: ["In-Thread_Middle", "In-Thread_Middle_Mobile"],
    },
    {
      templates: ["forum_list"],
      adUnits: ["In-Feed_LatestPosts", "Billboard"],
    },
    {
      templates: ["member_view"],
      adUnits: [
        "RightRail_Bottom_Adhesion",
        "ProfilePage_",
        "Mobile_Adhesion",
        "ProfilePage_Mobile_",
      ],
    },
    {
      templates: [
        "whats_new",
        "whats_new_posts",
        "xa_sc_whats_new_items",
        "xfmg_whats_new_media",
        "whats_new_looking_for_replies",
      ],
      adUnits: ["WhatsNew_Billboard"],
    },
    {
      templates: [
        "xfmg_media_index",
        "xfmg_media_user_index",
        "xfmg_album_index",
        "xfmg_album_user_index",
      ],
      adUnits: ["Mobile_Adhesion"],
    },
  ];
  this.nonGranularThreadAdUnitList = [
    "In-Thread_Lower_",
    "In-Thread_Lower_Mobile_",
  ];
  this.granularThreadAdUnitList = [
    "In-Thread_Lower_1",
    "In-Thread_Lower_2",
    "In-Thread_Lower_3",
    "In-Thread_Lower_Mobile_1",
    "In-Thread_Lower_Mobile_2",
    "In-Thread_Lower_Mobile_3",
  ];

  this.setAdViewed = function setAdViewed(cookieName) {
    const ONE_DAY = 1;
    const date = new Date();
    date.setDate(date.getDate() + ONE_DAY);
    XF.Cookie.set(cookieName, true, date);
  };

  this.isBillboardAd = function isBillboardAd(slotAdUnitPath) {
    const billboards = [
      BILLBOARD_AD_SLOT_NAME,
      HOMEPAGE_BILLBOARD_AD_SLOT_NAME,
      WHATS_NEW_BILLBOARD_AD_SLOT_NAME,
    ];

    return billboards.includes(slotAdUnitPath);
  };

  this.defineSlot = function defineSlot(id) {
    const slot = this.adSlots[id];

    // billboard logic check, if this returns false we dont need to build the adslot
    if (!this.billboardCheck(slot)) {
      return;
    }

    if (
      (slot.mobile && this.Config.isDesktop) ||
      (!slot.mobile && !this.Config.isDesktop)
    ) {
      if (!slot.alwaysShow) {
        return;
      }
    }

    if (slot.alwaysShow && !this.Config.inFeedAd) {
      return;
    }

    if (slot.minPostsOnpage && this.Config.postsOnPage < slot.minPostsOnpage) {
      return;
    }

    if (slot.iterated) {
      for (let i = 1; i <= this.Config.slotsToDefine; i++) {
        if (isRubiconAdOnlyOrNsfw()) {
          this.buildRubiconAdSlot([id, id + i]);
        } else if (this.Config.useFreeStarAds) {
          buildFreeStarAd(slot, id + i);
        } else {
          this.buildTag(slot, id + i);
          this.instantiatedAds.push([id, id + i]);
        }
      }
    } else if (isRubiconAdOnlyOrNsfw()) {
      this.buildRubiconAdSlot([id, id]);
    } else if (this.Config.useFreeStarAds) {
      buildFreeStarAd(slot, id);
    } else {
      this.buildTag(slot, id);
      this.instantiatedAds.push([id, id]);
    }
  }; // end define slot

  this.billboardCheck = function billboardCheck(slot) {
    const isBillboardAd = slot.adUnitPath === BILLBOARD_AD_SLOT_NAME;
    const isEnhancedBillboardAd =
      slot.adUnitPath === HOMEPAGE_BILLBOARD_AD_SLOT_NAME ||
      slot.adUnitPath === WHATS_NEW_BILLBOARD_AD_SLOT_NAME;

    // if this is a billboard ad and we dont have billboard
    // OR
    // if we are trying to show enhanced billboard while enhanced billboard is disabled
    // OR
    // and we are not on forum list and we have only forum_list billboard enabled
    if (
      (isBillboardAd && !this.Config.isBillboardEnabled) ||
      (isEnhancedBillboardAd && !this.Config.isEnhancedBillboardEnabled) ||
      (this.isBillboardAd(slot.adUnitPath) &&
        this.Config.onlyForumListBillboard &&
        this.Config.template !== "forum_list")
    ) {
      return false;
    }

    if (
      this.isBillboardAd(slot.adUnitPath) &&
      !this.Config.onlyForumListBillboard
    ) {
      const isEu = Boolean(this.Config.isEU);

      if (this.Config.useV3Billboard && isEu) {
        return false;
      }

      if (!this.Config.useV3Billboard) {
        const adViewed = XF.Cookie.get(slot.adUnitPath);
        // if they have seen the ad slot before, or the user is from EU, prevent showing the ad
        if (adViewed || isEu) {
          return false;
        }
        this.setAdViewed(slot.adUnitPath);
      }
    }

    return true;
  }; // end billboardCheck

  // build sortable tag
  this.buildTag = function buildTag(slot, id) {
    const { dfpId } = this;

    if (isRubiconAdOnlyOrNsfw()) {
      return;
    }

    if (this.Config.useFreeStarAds) {
      return;
    }

    const siteName = this.Config.sitename.replace(/\./g, "_");
    const slotUnit = `${dfpId + siteName}/${slot.adUnitPath}`;
    const isBillboardAd = this.isBillboardAd(slot.adUnitPath);

    if (
      (this.Config.onlyForumListBillboard &&
        slot.adUnitPath === BILLBOARD_AD_SLOT_NAME &&
        this.Config.template === "forum_list") ||
      (isBillboardAd && this.Config.useV3Billboard)
    ) {
      slot.mapping = this.tagSizeMappings.vsMutiSizeBillboardMapping;
    }
    googletag.cmd.push(() => {
      // define slot
      const tag = googletag.defineSlot(slotUnit, slot.size, id);
      if (slot.mapping) {
        tag.defineSizeMapping(slot.mapping);
      }

      if (isBillboardAd && !window.adConfig.useV3Billboard) {
        tag.setCollapseEmptyDiv(true, true);
      }

      if (slot.targeting) {
        Object.keys(slot.targeting).forEach((key) => {
          tag.setTargeting(key, slot.targeting[key]);
        });
      }

      if (this.Config.adsToRefresh[id] && !this.Config.adRefreshV2) {
        this.refreshSlot(tag, this.Config.adsToRefresh[id]);
      }

      tag.addService(googletag.pubads());

      // display id
      if (
        window.adConfig.useFixedAdExecOrder ||
        window.adConfig.openWrap ||
        window.adConfig.adRefreshV2
      ) {
        adIdsToDisplay.push(id);
        window.tagMaps[id] = tag;
      }

      if (!window.adConfig.openWrap) {
        if (!window.adConfig.useFixedAdExecOrder) {
          if (window.adConfig.useVanillaGPT) {
            googletag.cmd.push(() => {
              googletag.display(id);
            });
          } else {
            deployads.push(() => {
              deployads.gpt.display(id);
            });
          }
        }
      }
    });
  }; // end buildTag

  this.registerAdsForPage = function registerAdsForPage() {
    const validTemplateMaps = this.templateMaps.filter((templatesToAdUnits) => {
      return templatesToAdUnits.templates.indexOf(this.Config.template) > -1;
    });

    validTemplateMaps.forEach((templatesToAdUnits) => {
      templatesToAdUnits.adUnits.forEach((adId) => {
        this.defineSlot(adId);
      });
    });
  };

  this.refreshSlot = function refreshSlot(slot, refreshes) {
    const slotId = slot.getSlotElementId();
    this.refreshedAds[slotId] = refreshes;
    googletag.pubads().addEventListener("impressionViewable", (event) => {
      if (event.slot === slot && slotId in this.refreshedAds) {
        setTimeout(() => {
          if (!this.rubiconStackEnabled) {
            if (window.adConfig.useVanillaGPT || window.adConfig.openWrap) {
              googletag.cmd.push(() => {
                googletag.pubads().refresh([slot]);
              });
            } else {
              deployads.push(() => {
                deployads.gpt.pubadsRefresh([slot]);
              });
            }
          }
        }, this.refreshedAds[slotId].shift() * 1000);
        if (this.refreshedAds[slotId].length === 0) {
          delete this.refreshedAds[slotId];
        }
      }
    });
  };

  this.aboutAdsSet = false;
  this.renderAboutAdsOnClick = function renderAboutAdsOnClick() {
    if (this.aboutAdsSet) {
      return;
    }

    this.defineSlot("Homepage_Top_Mobile_About");
    this.defineSlot("Homepage_Vendor_Mobile_About");

    if (window.adConfig.useFixedAdExecOrder || window.adConfig.openWrap) {
      if (window.adConfig.useVanillaGPT || window.adConfig.openWrap) {
        googletag.cmd.push(() => {
          googletag.display("Homepage_Top_Mobile_About");
          googletag.display("Homepage_Vendor_Mobile_About");
        });
      } else {
        deployads.push(() => {
          deployads.gpt.display("Homepage_Top_Mobile_About");
          deployads.gpt.display("Homepage_Vendor_Mobile_About");
        });
      }
    }

    // Only want render calls to happen once
    this.aboutAdsSet = true;
  };

  this.rubiconStackEnabled = false;
  this.toggleAdsToRubiconOnly = function toggleAdsToRubiconOnly() {
    if (this.rubiconStackEnabled) {
      return;
    }
    if (isBrowsiOn()) {
      document.dispatchEvent(new Event("onAdSlotsDestroyed"));
      window.browsiBlockRefresh = true;
    }
    // Destroy the sortable ad slots altogether, so they do not refresh and replace the rubicon ads after the predetermined interval
    if (window.adConfig.useVanillaGPT || window.adConfig.openWrap) {
      googletag.cmd.push(() => {
        googletag.destroySlots();
      });
    } else {
      deployads.push(() => {
        deployads.gpt.destroySlots();
      });
    }
    googletag.pubads().disableInitialLoad();

    this.instantiatedAds.forEach((adId) => {
      this.buildRubiconAdSlot(adId);
    });

    this.rubiconStackEnabled = true;
  };

  this.buildRubiconAdSlot = function buildRubiconAdSlot(adId) {
    const adUnitPath = adId[0];
    const elementId = adId[1];
    const el = document.querySelector(`#${elementId}`);
    if (!el) {
      return;
    }

    const item = this.rubiconMapping[adUnitPath];
    if (!item) {
      return;
    }

    postscribe(
      `#${elementId}`,
      ` \
        <div class="rubicon-container"> \
          <script> \
            rp_account = "1019"; \
            rp_site = "25062"; \
            rp_adtype = "iframe"; \
            rp_smartfile = "[SMART FILE URL]"; \
            rp_kw = "[${this.Config.sitename}]"; \
            rp_inventory = { vsdomain: "${this.Config.sitename}" }; \
            rp_zonesize = "${item.zonesize}"; \
            rp_width = "${item.size[0]}"; \
            rp_height = "${item.size[1]}"; \
          </script> \
          <script src="https://ads.rubiconproject.com/ad/1019.js"></script> \
        </div> \
      `
    );
  };

  this.toggleRightRailMiddleAdhesion =
    function toggleRightRailMiddleAdhesion() {
      if (this.Config.sidebarMiddleAd) {
        const rightRailMiddleAdhesionTemplateMap = {
          templates: [
            "california_index_view",
            "california_marketplace_home",
            "forum_list",
            "forum_view",
            "thread_view",
            "article_view",
            "listing_view",
            "deal_view",
            "whats_new",
            "xa_sc_whats_new_items",
            "whats_new_posts",
            "xfmg_whats_new_media",
            "whats_new_looking_for_replies",
          ],
          adUnits: ["RightRail_Middle_Adhesion"],
        };
        this.templateMaps.push(rightRailMiddleAdhesionTemplateMap);
      }
      if (this.Config.sidebarMiddleAdExp) {
        googletag
          .pubads()
          .setTargeting("category", this.Config.sidebarMiddleAdExp);
      }
    };
  this.toggleGranularThreadAds = function toggleGranularThreadAds() {
    const THREAD_VIEW_AD_INDEX = 4;
    const ARTICLE_VIEW_AD_INDEX = 5;
    const LISTING_VIEW_AD_INDEX = 6;
    const DEAL_VIEW_AD_INDEX = 7;
    let applicableAdList = [];
    function deepExtend(...args) {
      const out = args[0] || {};
      for (let i = 1; i < args.length; i++) {
        const obj = args[i];
        if (!obj) continue;
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (typeof obj[key] === "object") {
              if (obj[key] instanceof Array) out[key] = obj[key].slice(0);
              else out[key] = deepExtend(out[key], obj[key]);
            } else out[key] = obj[key];
          }
        }
      }
      return out;
    }
    if (this.Config.enableGranularThreadAds) {
      deepExtend(this.adSlots, this.granularThreadAds);
      deepExtend(this.rubiconMapping, this.granularRubiconThreadAds);
      applicableAdList = this.granularThreadAdUnitList;
    } else {
      deepExtend(this.adSlots, !isBrowsiOn() ? this.nonGranularThreadAds : []);
      deepExtend(this.rubiconMapping, this.nonGranularRubiconThreadAds);
      if (!isBrowsiOn()) {
        applicableAdList = this.nonGranularThreadAdUnitList;
      } else if (this.Config.browsiGlobal) {
        // remove top Homepage_Top_Mobile
        this.templateMaps[3].adUnits = this.templateMaps[3].adUnits.filter(
          (i) => i !== "Homepage_Top_Mobile"
        );
      }
    }

    applicableAdList.forEach((threadAd) => {
      this.templateMaps[THREAD_VIEW_AD_INDEX].adUnits.push(threadAd);
      this.templateMaps[ARTICLE_VIEW_AD_INDEX].adUnits.push(threadAd);
      this.templateMaps[LISTING_VIEW_AD_INDEX].adUnits.push(threadAd);
      this.templateMaps[DEAL_VIEW_AD_INDEX].adUnits.push(threadAd);
    });
  };
  this.toggleDesktopAdhesion = function toggleDesktopAdhesion() {
    const { desktopAdhesion, isEU } = this.Config;

    if (
      desktopAdhesion &&
      (!this.Config.onlyForumListBillboard ||
        this.Config.template !== "forum_list")
    ) {
      // if billboard is visible hide ad slot element, because we won't be displaying ad, EU users never see billboards
      if (
        XF.Cookie.get("Desktop_Adhesion_Dismissed") ||
        (!isEU && this.isBillboardVisible())
      ) {
        desktopAdhesionClose(true);
        return;
      }

      this.templateMaps.forEach((templateMap) => {
        if (templateMap.adUnits.includes("Mobile_Adhesion")) {
          templateMap.adUnits.push("Desktop_Adhesion");
        }
      });
    }
  };
  // Checks if template supports billboard and duration cookie is present
  this.isBillboardVisible = () => {
    const adUnitName = billboardTemplates.get(this.Config.template);

    // if v3 billboard enabled, only show if we have a guest user
    if (this.Config.useV3Billboard) {
      // eslint-disable-next-line eqeqeq
      return adUnitName && this.Config.userId == 0;
    }

    return adUnitName && !XF.Cookie.get(adUnitName);
  };

  this.init = function init() {
    this.toggleGranularThreadAds();
    this.toggleRightRailMiddleAdhesion();
    this.toggleDesktopAdhesion();
    this.registerAdsForPage();

    if (XF.config.rubiconAdOnly) {
      this.toggleAdsToRubiconOnly();
      return;
    }

    if (this.Config.useFreeStarAds) {
      // inserted by PAGE_CONTAINER.html
      const { freestar } = window as unknown as { freestar: unknown };
      freestar.queue.push(() => {
        setPageTargeting();
      });
    } else {
      setPageTargeting();
      // enableServices called
      if (window.adConfig.useVanillaGPT || window.adConfig.openWrap) {
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      } else {
        deployads.push(() => {
          deployads.gpt.enableServices();
        });
      }
    }

    // display ads to be shown
    adIdsToDisplay.forEach((id) => {
      if (window.adConfig.adRefreshV2) {
        // observe slot and enable the new refresh
        observeAdSlotForRefresh(id);
      }
      if (window.adConfig.useFixedAdExecOrder || window.adConfig.openWrap) {
        if (window.adConfig.useVanillaGPT || window.adConfig.openWrap) {
          googletag.cmd.push(() => {
            googletag.display(id);
          });
        } else {
          deployads.push(() => {
            deployads.gpt.display(id);
          });
        }
      }
    });
    if (window.adConfig.useVanillaGPT || window.adConfig.openWrap) {
      googletag.pubads().refresh(googletag.pubads().getSlots());
    }
  }; // end this.init
};

if (!window.vsIsDesktop) {
  $(document)
    .on("focus", "textarea, input, .fr-wrapper", () => {
      $(".california-sticky-footer-ad-container").hide();
    })
    .on("blur", "textarea, input, .fr-wrapper", () => {
      $(".california-sticky-footer-ad-container").show();
    });
}
googletag.cmd.push(() => {
  window.Ads = new California.Ads(window.adConfig);
  if (window.adConfig.amazonTAM) {
    setAmazonTAMRefreshOverride();
  }
  window.Ads.init();
  window.adsLoaded = true;

  if (document.getElementById("about")) {
    document
      .getElementById("about")
      .addEventListener(
        "click",
        window.Ads.renderAboutAdsOnClick.bind(window.Ads)
      );
  }
});
