export function buildFreeStarAd(slot, id) {
  // inserted by PAGE_CONTAINER.html + defined by display_ads_js.html
  const { fsSite } = window as unknown as { fsSite: string };
  let placementName = `${fsSite}_${id}`;

  // create targeting object
  const targetingObj = {};
  if (slot.targeting) {
    Object.keys(slot.targeting).forEach((key) => {
      targetingObj[key] = slot.targeting[key];
    });
  }

  if (/^.*_\d$/.test(placementName)) {
    placementName = placementName.substring(0, placementName.length - 2);
  }

  // inserted by PAGE_CONTAINER.html
  const { freestar } = window as unknown as { freestar: unknown };
  freestar.config.enabled_slots.push({
    placementName,
    slotId: id,
    targeting: targetingObj,
  });
}
