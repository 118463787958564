import type { XF } from "./XF";

interface California {
  /** [reference](../../admin/admin_edit.ts) */
  AdminSetDefaultAdminPermissions: unknown;
  /** [reference](../../admin/moderator_edit.ts) */
  AdminStaffSetModPermissions: unknown;
  /** [reference](../../admin/moderator_edit.ts) */
  AdminStaffSetSuperModPermissions: unknown;
  /** [reference](../../admin/moderator_edit.ts) */
  AdminStaffSetSectionModPermissions: unknown;
  /** [reference](./message-override.ts) */
  NestedPostReplyClick: typeof XF.Event;
  /** [reference](./message-override.ts) */
  ShareUrlClick: typeof XF.Event;
  /** [reference](./editor-overrides.ts) */
  editorButton: {
    init: () => void;
    initializeDialog: () => void;
    callback: () => void;
  };
  /** [reference](../california_wayfinding.ts) */
  Wayfinder: unknown;
}

export const California = (window.California || {}) as Partial<California>;
window.California = California;
