import { XF } from "./XF";

export function isRubiconAdOnlyOrNsfw() {
  const { adConfig } = window;

  return (
    (adConfig && adConfig.showSensitiveContent && adConfig.pageContainsNsfw) ||
    XF.config.rubiconAdOnly
  );
}

/**
 * Removes the JW player from the page
 */
export function removeVideoPlayer() {
  // Ensure that we have a JWPlayer to remove
  if (typeof jwplayer !== "undefined") {
    // Get a reference to our current video player

    // TODO: remove disable after existing `no-undef` cases are resolved
    // eslint-disable-next-line no-undef
    const video = jwplayer("JWPlayer");

    // Confirm that we have a video, and that it can be removed
    if (typeof video !== "undefined" && typeof video.remove !== "undefined") {
      // Remove it!
      video.remove();
    }
  }
}
